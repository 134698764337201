.trans {
    transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    -webkit-transition: all 1s ease;
}

.block {
    display: block;
    width: 25%;
    position: relative;

}

.block ul {
    list-style: none;
    width: 100%;
    height: 100%;
    z-index: 1;
    box-sizing: border-box;
    padding: 0;
}

.block ul li {
    position: relative;
    float: left;
    //width: 25%;
    //height: 25%;
    overflow: hidden;
    border: 12px solid rgba(0, 0, 0, 0);
    cursor: pointer;

    .searchIcon {
        display: none;
    }
}

.block ul li::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    content: '';
    color: white;
    opacity: 0.1;
    text-align: center;
    box-sizing: border-box;
    pointer-events: none;
    transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    border-radius: 10px;
}

.block ul li:hover::before {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.90);
}

.top ul li img {
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 10px;
}

.lightbox {
    position: fixed;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 9999;
    display: none;
    cursor: pointer;
}

.lightbox img {
    max-width: 90%;
    max-height: 80%;
    position: relative;
    border-radius: 10px;
    //top: -100%;
    /* Transition */
    transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    -webkit-transition: all 1s ease;
}

.lightbox:target {
    outline: none;
    top: 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 1.2s ease;
    -moz-transition: all 1.2s ease;
    -ms-transition: all 1.2s ease;
    -o-transition: all 1.2s ease;
    -webkit-transition: all 1.2s ease;
}

.lightbox:target img {
    top: 0;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}

.lightbox.show {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
}


@media screen and (max-width: 1000px) {
    /* .block {
        display: block;
        width: 33.33333333333%;
    } */
}


/*Mobile*/
@media screen and (max-width: 800px) {
    .block {
        display: block;
        width: 50%;
    }

    #portifolio h2 {
        font-size: 40px;
        margin: 0;
    }

    .block ul li {

        .searchIcon {
            display: block;
            width: 100%;
            max-height: 40px;
            background: rgba(0, 0, 0, 0.5);
            position: absolute;
            bottom: 0;
            z-index: 9999;
            left: 0;
            padding: 5px;
            border-radius: 0 0 10px 10px;

            img {
                width: 18px;
            }
        }
    }
}