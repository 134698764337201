#fantasia {
    .content {
        padding: 60px 0 80px;
        width: 70%;
        margin: 0 auto;

        h1,
        h2 {
            margin: 0 0 40px;
            font-weight: bold;
            font-size: 40px;
            color: #002B56;
        }

        .box {
            margin-bottom: 40px;
            border-radius: 30px;
            padding: 20px;


            h2 {
                font-size: 32px;
                margin: 0 0 20px;
            }

            .w_img {
                display: flex;
                justify-content: space-between;

                .block {
                    width: 50%;

                    ul li::before {
                        border-radius: 30px;
                        opacity: 0;
                    }

                    .lightbox img {
                        width: unset;
                    }
                }

                img {
                    width: 100%;
                    border-radius: 30px;
                    border: 10px solid #FFD119;
                }
            }
        }
    }
}


@media screen and (max-width: 800px) {
    #fantasia {
        .content {
            padding: 30px 0 0;
            width: 90%;


            h1,
            h2 {
                margin: 0 0 30px;
                font-size: 28px;
            }

            .box {
                margin-bottom: 30px;
                border-radius: 20px;
                padding: 0;


                h2 {
                    font-size: 22px;
                    margin: 0;
                }

                .w_img {
                    display: flex;
                    justify-content: space-between;

                    img {
                        border-radius: 10px;
                        border: 5px solid #FFD119;
                    }

                    .block {
                        display: block;
                        width: 50%;
                    }

                    .block ul li {
                        border: 5px solid rgba(0, 0, 0, 0);

                        .searchIcon {
                            display: block;
                            width: 100%;
                            max-height: 60px;
                            background: rgba(0, 0, 0, 0.5);
                            position: absolute;
                            bottom: 0;
                            z-index: 9999;
                            left: 0;
                            padding: 5px 0 10px;
                            border-radius: 0 0 10px 10px;

                            img {
                                width: 18px;
                                border: none;
                            }
                        }
                    }
                }
            }
        }
    }
}