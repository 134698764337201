#sobre {
    background: rgba(255, 209, 25, 0.8);
    padding: 60px 0 80px;
    color: #002B56;

    .indent {
        width: 84%;
        margin: 0 auto;


        h2 {
            margin: 0 0 40px;
            font-weight: 400;
            font-size: 55px;
            color: #002B56;
            font-weight: bold;
            text-align: center;
        }

        p {
            margin: 0;
            font-size: 23px;
            text-align: justify;
        }

        .wrap {
            margin-bottom: 20px;

            .w_block {
                display: flex;
                justify-content: center;

                .block {
                    width: 300px;
                    padding: 15px;
                    margin: 0 50px;
                }
            }



            .block-text {
                width: 100%;
                margin-bottom: 30px;
            }

            img {
                width: 100%;
                border-radius: 50%;
            }
        }

        .vision {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin: 0 auto;

            .block {
                width: 32%;
                padding: 5px 30px 40px;
                text-align: center;
                border: 5px solid rgba(255, 209, 25, 1);
                border-radius: 30px;

                p {
                    text-align: center;
                }

                img {
                    margin: 15px;
                    width: 123px;
                }
            }
        }
    }
}

@media screen and (max-width: 1100px) {

    #sobre {
        padding: 40px 0 60px;
    }

    #sobre .indent .vision {
        flex-direction: column;
    }

    #sobre .indent .vision .block {
        width: 100%;
        margin-bottom: 25px;
    }

    #sobre .indent p {
        font-size: 21px;
    }

}


/*Mobile*/
@media screen and (max-width: 800px) {
    #sobre {
        padding: 30px 0;
        
        .indent {
            p {
                font-size: 18px;
                text-align: center;
            }

            h2 {
                font-size: 40px;
                margin: 0 0 20px;
            }

            .wrap {
                flex-direction: column;
    
                .block-text {
                    width: 100%;
                    padding: 0;
                    margin-bottom: 20px;
                }
    
                .w_block {
                    
    
                    .block {
                        width: 50%;
                        padding: 15px;
                        margin: 0;
                    }
                }
    
    
            }

            .vision {
                flex-direction: column;
    
                .block {
                    width: 100%;
                    padding: 20px;
                    img {
                        margin: 15px;
                        width: 90px;
                    }
                }
            }
        }
    }
}