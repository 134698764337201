.NavPagesBlog {
    h2 {
        text-align: left;
        padding: 10px 0;
        font-size: 30px;
        color: #002B56;
    }

    .indent {
        width: 84%;
        margin: 30px auto 0;

        ul {
            padding: 0;

            li {
                list-style: none;
                padding: 20px 0;
                border-top: 2px solid #002B56;
                margin: 0;
                cursor: pointer;

                &:last-child {
                    border-bottom: 2px solid #002B56;
                }

                a {
                    color: #002B56;
                    text-decoration: none;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    img {
                        margin-left: 20px;
                    }

                    p {
                        margin: 0 !important;
                        font-size: 20px;
                        text-decoration: none;
                        font-weight: 500;
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 800px) {
    .NavPagesBlog {
        .indent {
            ul {
                li {
                    padding: 15px 0;
                }
            }
        }
    }
}