#bpage {
    padding: 60px 0 80px;

    .indent {
        width: 84%;
        margin: 0 auto 50px;


        h1 {
            margin: 0 0 40px;
            font-weight: 400;
            font-size: 45px;
            color: #002B56;
            font-weight: bold;
            text-align: center;
        }

        p, h2 {
            margin: 0 0 15px;
            font-size: 23px;
            text-align: left;
        }

        span, h2 {
            font-weight: bold;
        }
    }

    .w_img {
        display: flex;
        justify-content: space-between;

        .block {
            width: 50%;

            ul li::before {
                border-radius: 30px;
                opacity: 0;
            }

            .lightbox img {
                width: unset;
            }
        }

        img {
            width: 100%;
            border-radius: 30px;
            border: 10px solid #FFD119;
        }
    }
}



@media screen and (max-width: 800px) {
    #bpage {
        padding: 30px 0;

        .indent {
            margin: 0 auto 30px;

            p {
                font-size: 18px;
            }

            h1 {
                font-size: 30px;
                margin: 0 0 20px;
            }

            h1 {
                font-size: 30px;
            }
        }

        .w_img {
            display: flex;
            justify-content: space-between;

            img {
                border-radius: 10px;
                border: 5px solid #FFD119;
            }

            .block {
                display: block;
                width: 50%;
            }

            .block ul li {
                border: 5px solid rgba(0, 0, 0, 0);

                .searchIcon {
                    display: block;
                    width: 100%;
                    max-height: 60px;
                    background: rgba(0, 0, 0, 0.5);
                    position: absolute;
                    bottom: 0;
                    z-index: 9999;
                    left: 0;
                    padding: 5px 0 10px;
                    border-radius: 0 0 10px 10px;

                    img {
                        width: 18px;
                        border: none;
                    }
                }
            }
        }

    }
}