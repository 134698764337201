#footer {
  background: #fff;
  position: relative;

  .mobile {
    display: none;
  }

  .w_logos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 45%;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: center;
    position: relative;

    .logo {
      width: 100%;

      img {
        width: 100%;
      }
    }

    .shadow {
      position: absolute;
      background: rgb(0, 43, 86, 0.8);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .logo_oa {
      img {
        width: 350px;
        margin: 0 auto;
        position: relative;
        z-index: 999;
      }
    }
  }


  .btn-orcamento {
    width: 500px;
    border-radius: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #002B56;
    background: #FFD119;
    padding: 15px 50px;
    margin: 10px 0 60px;
    letter-spacing: 1px;
    cursor: pointer;

    p {
      font-weight: bold;
      font-size: 40px;
    }
  }

  h2 {
    font-family: 'Rowdies';
    margin: 0 0 30px;
    font-weight: 400;
    font-size: 40px;
    text-align: center;
  }

  .whatsapp {
    position: fixed;
    right: 45px;
    bottom: 60px;
    z-index: 999;
    display: block;


    p {
      margin-left: 5px;
    }
  }


  p,
  h3 {
    margin: 0;
    font-size: 25px;
    text-align: start;
  }

  h3 {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .wrapper {
    display: flex;
    justify-content: center;
    width: 55%;
    background: #002B56;
    color: #fff;
  }

  .copy {
    margin: auto 0 0;
    background: rgba(0, 43, 86, 0.9);
    padding: 10px 0;
    color: #fff;

    p {
      font-size: 14px;
      text-align: center;
    }
  }

  .wrap_footer {
    display: flex;
  }

  .wrapper {
    padding: 80px 0 60px 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;


    .info {
      margin: 0 0 20px;
      display: flex;
      align-items: flex-start;

      img {
        margin-right: 20px;
        margin-top: 5px;
      }

      p {
        text-align: left;
      }

      .ml {
        margin-left: 34px;
      }

      .iadress {
        margin-top: 5px;
      }

      .btn_voltar {
        transform: rotate(90deg);
        width: 28px;
        cursor: pointer;
      }

    }

    .links {
      text-align: start;
    }

    .mail,
    .tel,
    .redes {
      a {
        font-size: 25px;
        text-decoration: none;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 1550px) {

  #footer .wrapper .mail a,
  #footer .wrapper .tel a,
  #footer .wrapper .redes a {
    font-size: 23px;
  }

  #footer {

    p,
    h3 {
      font-size: 23px;
    }
  }
}


@media screen and (max-width: 1250px) {
  #footer {

    p,
    h3 {
      font-size: 21px;
    }
  }

  #footer .btn-orcamento {
    width: 380px;
    border-radius: 55px;
  }

  #footer .btn-orcamento p {
    font-size: 35px;
  }
}


@media screen and (max-width: 1050px) {
  #footer .wrapper {
    padding: 60px 0 60px 30px;
  }

}



@media screen and (max-width: 900px) {

  #footer .wrapper .mail a,
  #footer .wrapper .tel a,
  #footer .wrapper .redes a {
    font-size: 20px;
  }

  #footer {

    p,
    h3 {
      font-size: 18px;
    }
  }
}



/* Mobile */
@media screen and (max-width: 800px) {

  #footer .wrapper .mail a {
    margin: 0;
    justify-content: center;
  }

  #footer {

    .copy p {
      font-size: 13px;
    }

    .wrap_footer {
      flex-direction: column-reverse;
    }

    .mobile {
      display: block;
    }

    .desktop {
      display: none;
    }

    .wrapper .info img {
      width: 30px;
      margin: 0 10px 0 0;
    }

    .w_logos {
      width: 100%;
      padding: 20px 0;
      flex-direction: column;

      .logo {
        margin: 0 0 25px;

        img {
          margin: 0;
          width: 80%;
        }
      }

      .logo_oa {
        img {
          width: 250px;
        }
      }
    }



    h2 {
      margin: 0 0 25px 0;
    }

    p,
    h3 {
      font-size: 18px;
      text-align: center;
    }

    .wrapper {
      flex-direction: column;
      align-items: center;
      padding: 40px 20px 50px;
      width: 100%;

      .links {
        text-align: center;
      }

    }

    .wrapper .info {
      text-align: center;
      margin: 0 0 30px 0;

      &:last-child {
        margin: 0;
      }

    }

    .whatsapp {
      bottom: 50px;
      right: 20px;
      z-index: 9999;
    }


    .wrapper .info p {
      text-align: center;
    }

    .btn-orcamento {
      padding: 13px 35px;
      margin: 0 0 30px;
      width: 310px;

      p {
        font-size: 20px;
      }
    }

    .email_text {
      font-size: 18px;
    }
  }

}