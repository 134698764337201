#clientes {
    background-position: top;
    padding: 50px 25px 40px;
    width: 100vw;
    //border-top: 30px solid #044f9c;


    h1 {
        margin: 10px 0 0;
        font-weight: bold;
        font-size: 55px;
        color: #002B56;
        text-align: center;
        position: relative;

        span {
            opacity: 0;
            position: absolute;
        }
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        border-top: 7px solid #000 !important;
        border-right: 7px solid #000 !important;
    }

    .carousel-indicators [data-bs-target] {
        background-color: #000 !important;
    }


    .container {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 50px !important;
    }

    .top {
        display: flex;
        //height: 80vh;
        margin-left: auto;
        margin-right: auto;
        flex-wrap: wrap;

        .mascote3d {
            width: 25%;
            border-radius: 10px;
            padding: 12px;

            a {
                position: relative;
                height: 100%;
                width: 100%;
                display: block;

                .searchIcon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    max-height: 40px;
                    background: rgba(0, 0, 0, 0.3);
                    position: absolute;
                    z-index: 9999;
                    border-radius: 8px 0 8px 0;
                    width: 36px;
                    right: 0;
                    bottom: 0;
                    border: none;
                    padding: 8px 0;

                    picture {
                        width: 20px;

                        img {
                            width: 100%;
                            border: none;
                            box-shadow: none;
                            margin: 0 auto;
                        }
                    }

                }
            }


            img {
                width: 100%;
                border-radius: 10px;
                box-shadow: 2px 2px 8px #ededed;
            }
        }
    }


    /* .carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 3rem !important;
    height: 3rem !important;
}
 */
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        background-image: none !important;
        border-top: 7px solid #fff;
        border-right: 7px solid #fff;
        opacity: 1;
    }

    .carousel-control-next,
    .carousel-control-prev {

        opacity: 1;
    }

    /* 
.carousel-control-next {
    right: -10px !important;
}

.carousel-control-prev {
    left: -10px !important;
}
 */
    .carousel-control-next-icon {
        transform: rotate(45deg);
    }

    .carousel-control-prev-icon {
        transform: rotate(225deg);
    }



}




@media screen and (max-width: 1100px) {

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        display: inline-block;
        width: 2rem !important;
        height: 2rem !important;
    }

    .carousel-control-next,
    .carousel-control-prev {
        width: 10% !important;
    }

    #clientes h1 {
        font-size: 50px;
    }
}

/* Mobile */
@media screen and (max-width: 800px) {

    #clientes h1 {
        font-size: 40px;
    }

    #clientes {
        padding: 30px 5px 10px;

        .top {
            .mascote3d {
                width: 50%;

                a {
                    .searchIcon {
                        width: 100%;
                        max-height: 40px;
                        background: rgba(0, 0, 0, 0.5);
                        position: absolute;
                        bottom: 0;
                        z-index: 9999;
                        left: 0;
                        padding: 5px 0;
                        border-radius: 0 0 10px 10px;

                        picture {
                            display: block;
                            width: 18px;
                        }

                    }
                }

            }
        }

        .carousel {
            padding: 0 5px 20px;
        }

        .container {
            padding: 20px !important;
        }
    }



    .carousel-control-next,
    .carousel-control-prev {
        width: 13% !important;
    }


    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        display: inline-block;
        width: 1.5rem !important;
        height: 1.5rem !important;
    }

}


@media screen and (max-width: 380px) {

    #clientes h1 {
        font-size: 38px;
    }

}