@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

a {
    text-decoration: none;
    border: none;
    
}

header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-height: 100px;
    padding: 0 25px;
    width: 100%;
    background: #044f9c;

    .bars {
        display: none;
    }

    .close-menu {
        display: none;
    }

    .logo {
        width: 300px;
        margin: 10px 40px 10px 0;
        display: block;

        img {
            width: 100%;
        }
    }


    .active {
        border-bottom: 2px solid #ffed37;
        color: #ffed37 !important;
        opacity: 1 !important;

        &:hover {
            opacity: 1 !important;
            color: #ffed37 !important;
        }
    }

    .unactive {
        border: none !important;
        text-decoration: none !important;
    }


    nav {
        background: none;

        ul {
            li {
                a {
                    padding: 0 10px !important;
                    color: #fff !important;
                    font-size: 25px !important;
                    font-weight: 700 !important;
                    opacity: 0.9 !important;
                    border: none !important;

                    &:hover {
                        opacity: 1 !important;
                        color: #ffed37 !important;
                        border: none !important;
                    }
                }
            }
        }
    }


}

@media screen and (max-width: 1100px) {
    header .logo {
        width: 300px;
    }

    header nav ul li a {
        font-size: 22px !important;
    }
}

@media screen and (max-width: 900px) {
    header {
        .logo {
            width: 280px;
        }

        nav ul li a {
            font-size: 20px !important;
        }

        nav {
            display: none;
        }
    }     
}

/* Mobile */
@media screen and (max-width: 800px) {
    header {
        display: flex;
        flex-direction: column;

        .logo{ 
            padding: 5px 0;
        }

        .mobile {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .bars {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 40px;
            height: 25px;
            //border: 1px solid black;

            .line {
                width: 100%;
                height: 3px;
                background: #fff;
            }
        }

        .bg-mobile {
            position: fixed;
            width: 100vw;
            height: 100vh;
            background: rgba(0, 0, 0, 0.5);
            top: 0;
            left: 0;
            z-index: 999;
        }

        .close-menu {
            display: block;
            width: 35px;
            height: 35px;
            position: fixed;
            right: 30px;
            top: 30px;
            z-index: 9999;

            &::before {
                content: '';
                width: 100%;
                height: 4px;
                position: absolute;
                top: 50%;
                left: 0;
                background: #fff;
                rotate: 45deg;
            }

            &::after {
                content: '';
                width: 100%;
                height: 4px;
                position: absolute;
                top: 50%;
                left: 0;
                background: #fff;
                rotate: 135deg;
            }
        }



        .logo {
            width: 75%;
            margin: 0;
        }

        nav.navbar {
            display: none;

            ul {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                width: 100vw;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 999;
                padding: 40px 0;
                background: #184a74;

                li {
                    width: 100%;
                    text-align: center;
                    padding: 10px;
                }
            }
        }


        nav.navbar.show {
            display: block;
            //animation: fadeIn 1s;
            transition: 2s;
        }
    }
}