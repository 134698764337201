#banners {

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        background-image: none !important;
        border-top: 7px solid #fff;
        border-right: 7px solid #fff;
        //opacity: 0.5;
    }

    .carousel-control-next,
    .carousel-control-prev {

        //opacity: 1;
    }

    .carousel-control-next-icon {
        transform: rotate(45deg);
    }

    .carousel-control-prev-icon {
        transform: rotate(225deg);
    }

    .carousel-indicators {
        justify-content: left;
        margin-bottom: 20px;
        margin-left: 70px;
        border-radius: 10px;

        button {
            width: 100px;
            background: #ededed;
            height: 2px;
            border-radius: 10px;
            margin-right: 5px;
        }

        .carousel-indicators {
            margin-right: 5px !important;
            margin-left: 5px;
        }
    }

}

@media screen and (max-width: 800px) {
    #banners {

        .carousel-control-next,
        .carousel-control-prev {
            display: none !important;
        }

        .carousel-indicators {
            margin-bottom: 15px;
            margin-left: 15px;
            width: 50%;
            border-radius: 7px;

            button {
                height: 0;
                border-radius: 10px;
                margin-right: 4px;
                border-top: 7px solid transparent;
                border-bottom: 7px solid transparent;
            }

            .carousel-indicators {
                margin-right: 5px !important;
                margin-left: 5px;
            }
        }
    }
}