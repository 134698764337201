.App {
  text-align: center;
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@font-face {
  font-family: 'Rowdies';
  src: url('./fonts/Rowdies-Regular.eot');
  src: url('./fonts/Rowdies-Regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/Rowdies-Regular.woff2') format('woff2'),
      url('./fonts/Rowdies-Regular.woff') format('woff'),
      url('./fonts/Rowdies-Regular.ttf') format('truetype'),
      url('./fonts/Rowdies-Regular.svg#Rowdies-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}



ul, ol, li, a { padding: 0; margin: 0 ;}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
