#galeria {
    background: url('../images/bg-portifolio.jpg') center no-repeat;
    background-repeat: no-repeat;
    background-size: cover !important;
    background-position: top;
    padding: 25px 25px 40px;
    /* border-top: 30px solid #044f9c; */
    width: 100vw;

    h2 {
        margin: 20px 0 0;
        font-weight: bold;
        font-size: 55px;
        color: #fff;
    }


    body {
        background-color: black;
    }

    .container {
        width: 100%;
        height: 100%;
        display: flex;
        padding: 50px !important;
    }

    .top {
        display: flex;
        //height: 80vh;
        margin-left: auto;
        margin-right: auto;
        flex-wrap: wrap;
    }


    /* .carousel-control-next-icon,
.carousel-control-prev-icon {
    display: inline-block;
    width: 3rem !important;
    height: 3rem !important;
}
 */
    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        background-image: none !important;
        border-top: 7px solid #fff;
        border-right: 7px solid #fff;
        opacity: 1;
    }

    .carousel-control-next,
    .carousel-control-prev {

        opacity: 1;
    }

    /* 
.carousel-control-next {
    right: -10px !important;
}

.carousel-control-prev {
    left: -10px !important;
}
 */
    .carousel-control-next-icon {
        transform: rotate(45deg);
    }

    .carousel-control-prev-icon {
        transform: rotate(225deg);
    }

}



@media screen and (max-width: 1100px) {

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        display: inline-block;
        width: 2rem !important;
        height: 2rem !important;
    }

    .carousel-control-next,
    .carousel-control-prev {
        width: 10% !important;
    }

    #galeria h2 {
        font-size: 50px;
    }
}

/* Mobile */
@media screen and (max-width: 800px) {

    #galeria h2 {
        font-size: 45px;
    }

    #galeria {
        padding: 25px 5px 40px;
        border-top: 15px solid #044f9c;

        .carousel {
            padding: 0 5px 20px;
        }
        
        .container {
            padding: 20px !important;
        }
    }

    

    .carousel-control-next,
    .carousel-control-prev {
        width: 13% !important;
    }


    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        display: inline-block;
        width: 1.5rem !important;
        height: 1.5rem !important;
    }

}