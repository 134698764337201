#mascotes {
    padding: 60px 0 80px;

    .indent {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        width: 84%;
        margin: 30px auto 0;

        h1,
        h2,
        h3,
        h4,
        p {
            font-size: 22px;
            text-align: left;
            padding: 15px 0;
            border-top: 2px solid #002B56;
            color: #002B56;
            font-weight: bold;
            margin: 0;
        }

        h1,
        h4 {
            font-weight: normal;
        }

        h2,
        h3 {
            text-align: left;
        }

        p {
            font-weight: normal;
        }

        .mascote_images {
            width: 60%;

            picture {
                display: block;
                padding: 0 100px 50px;

                img {
                    border-radius: 20px;
                }
            }
        }

        .mascote_description {
            width: 37%;
        }
    }


    .carousel-control-next-icon,
    .carousel-control-prev-icon {
        background-image: none !important;
        border-top: 7px solid #002B56;
        border-right: 7px solid #002B56;
        //opacity: 1;
    }


    .carousel-control-next-icon {
        transform: rotate(45deg);
    }

    .carousel-control-prev-icon {
        transform: rotate(225deg);
    }

    .carousel-indicators {
        justify-content: left;
        margin-bottom: 0;
        margin: 0 !important;
        border-radius: 10px;
        display: flex;
        justify-content: center;

        button {
            width: 60px;
            background: #002B56;
            height: 2px;
            border-radius: 10px;
            margin-right: 7px;
        }

        .carousel-indicators {
            margin-right: 0 !important;
            margin-left: 0;

        }
    }
}


@media screen and (max-width: 1100px) {
    #mascotes {
        padding: 40px 0 60px;

        .indent {
            .mascote_images {
                picture {
                    display: block;
                    padding: 0 50px 50px;

                    img {
                        border-radius: 20px;
                    }
                }
            }

            h1,
            h2,
            h3,
            h4,
            p {
                font-size: 20px;
            }

            .mascote_description {
                width: 35%;
            }

                .carousel-indicators button {
                width: 40px;
                margin-right: 5px;
            }
        }
    }
}



@media screen and (max-width: 800px) {
    #mascotes {
        padding: 30px 0 40px;

        .indent {
            flex-direction: column;

            h1,
            h2,
            h3,
            h4,
            p {
                font-size: 18px;
                padding: 10px 0;
            }


            .mascote_images {
                width: 100%;
                margin-bottom: 50px;

                picture {
                    padding: 0 65px 40px;
                }
            }

            .mascote_description {
                width: 100%;
            }

            .carousel-indicators {
                button {
                    width: 28px;
                    margin-right: 6px;
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    #mascotes {
        .indent {

            .carousel-control-next,
            .carousel-control-prev {
                width: 8% !important;
            }

            .mascote_images {
                picture {
                    padding: 0 30px 40px;
                }
            }
        }
    }
}