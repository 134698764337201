#solucoes {
    background: #034F9B;
    padding: 60px 0 80px;
    width: 100%;

    .menusolucoes {
        display: flex;
        justify-content: center;
        width: 84%;
        margin: 0 auto;

        .titulo {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            height: 6vw;
            position: relative;


            img {
                height: 100%;
                position: absolute;
                left: 0;
            }

            h2 {
                font-size: 75px;
                font-weight: 500;
                color: #fff;
                margin: 0;
            }
        }

        .solucao {
            width: 45%;


            nav {
                margin-top: 40px;
                width: 100%;

                ul {
                    padding: 0;

                    li {
                        font-size: 32px;
                        line-height: 1.8;
                        color: #fff;
                        cursor: pointer;
                        border: 3px solid #ffff;
                        border-radius: 32px;
                        list-style: none;
                        padding: 6px 10px;
                        text-align: center;
                        margin-bottom: 15px;


                        &:hover {
                            color: #FFD119;
                            border-color: #FFD119;
                        }

                        &.active {
                            color: #034F9B;
                            background: #fff;
                            font-weight: bold;
                            border-color: #fff;
                        }
                    }
                }
            }


        }

        .images {
            width: 55%;
            margin-left: 50px;

            .wrap-box {
                margin-top: 40px;
                width: 100%;

                img, iframe {
                    border-radius: 25px;
                    display: none;
                    width: 100%;

                    &.active {
                        display: block;
                    }

                    &.pattern {
                        display: block;
                    }
                }
            }
        }
    }

    #mobile_shadow {
        display: none;
    }
}

@media screen and (max-width: 1550px) {
    #solucoes {
        .menusolucoes {
            .solucao {
                nav {
                    ul {
                        li {
                            font-size: 26px;
                            line-height: 1.8;
                            border-radius: 26px;
                            padding: 5px 10px;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 1250px) {
    #solucoes .menusolucoes .solucao nav ul li {
        font-size: 22px;
        line-height: 1.6;
        margin-bottom: 10px;
    }

    #solucoes .menusolucoes .titulo h2 {
        font-size: 55px;
    }
}


@media screen and (max-width: 1100px) {

    #solucoes .menusolucoes .solucao nav ul li {
        font-size: 20px;
        line-height: 1.6;
        margin-bottom: 10px;
    }


    #solucoes {
        background: #034F9B;
        padding: 40px 0 60px;
    }
}

@media screen and (max-width: 800px) {

    #solucoes {
        padding: 30px 0;
        position: relative;
    }

    #solucoes .menusolucoes .titulo h2 {
        font-size: 40px;
        line-height: 1.2;
    }

    #solucoes .menusolucoes .solucao nav ul li.active {
        color: #fff;
        background: unset;
        font-weight: unset;
        border-color: #fff;
    }

    #solucoes #mobile_shadow {
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
        content: '';
        &.active{
            display: block;
        }
    }

    #solucoes .menusolucoes .images .wrap-box img.active, #solucoes .menusolucoes .images .wrap-box iframe.active  {
        position: fixed;
        top: 30%;
        right: 0;
        width: 100%;
        padding: 0 10%;
        border-radius: 20%;
        z-index: 999999;
    }

    #solucoes .menusolucoes .images .wrap-box iframe.active  {
        border-radius: 0;
        top: 15%;
    }

    #solucoes .menusolucoes .images {
        width: 0;
        margin-left: 0;
    }

    #solucoes .menusolucoes .solucao {
        width: 100%;
    }

    #solucoes .menusolucoes .titulo img {
        display: none;
    }

    #solucoes .menusolucoes .solucao nav {
        margin-top: 20px;
    }

    #solucoes .menusolucoes .titulo {
        height: unset;
    }

    #solucoes .menusolucoes .solucao nav ul li {
        margin-bottom: 15px;
    }

}